import { prevMonth } from '@geovelo-frontends/commons';
import { Box } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import ReportDetails from '../../../../components/report-details';
import useQueryParams from '../../../../hooks/query-params';
import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TCartographicDataPageContext } from '../../context';

import AccidentologyForm from './form';

function AccidentologyTab(): JSX.Element {
  const context = useOutletContext<TCartographicDataPageContext & TOutletContext>();
  const { period } = context;
  const { getPeriods } = useQueryParams();

  useEffect(() => {
    const defaultPeriods = getPeriods(
      moment().get('date') <= 7 ? prevMonth.getPrevPeriod() : prevMonth,
    );

    period.setValues(defaultPeriods);
  }, []);

  const { selectedReport } = context.accidentology;

  return (
    <PageContentLayout
      leftPanel={<AccidentologyForm {...context} />}
      main={
        <>
          <StyledCanvas id="accidentology-deck-canvas" ref={context.accidentology.canvasRef} />
          <Box id="incident-tooltip" position="absolute" sx={{ pointerEvents: 'none' }} />
        </>
      }
      rightPanel={
        <ReportDetails
          hasActions
          reports={selectedReport ? [selectedReport] : []}
          selectedId={selectedReport?.id || null}
        />
      }
    />
  );
}

const StyledCanvas = styled.canvas`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export default AccidentologyTab;
