import { prevMonth } from '@geovelo-frontends/commons';
import moment from 'moment';
import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import CounterDetails from '../../../../components/counter-details';
import useQueryParams from '../../../../hooks/query-params';
import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { IBicycleObservatoryPageContext } from '../../context';

import RoadsUseNewForm from './form';

function RoadsUseAnalysisTab(): JSX.Element {
  const context = useOutletContext<IBicycleObservatoryPageContext & TOutletContext>();
  const { period } = context;
  const { getPeriods } = useQueryParams();

  useEffect(() => {
    const defaultPeriods = getPeriods(
      moment().get('date') <= 7 ? prevMonth.getPrevPeriod() : prevMonth,
    );

    period.setValues(defaultPeriods);
  }, []);

  return (
    <PageContentLayout
      leftPanel={<RoadsUseNewForm {...context} />}
      rightPanel={<CounterDetails {...context} />}
    />
  );
}

export default RoadsUseAnalysisTab;
