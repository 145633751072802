import { prevMonth, useAmplitudeTracker } from '@geovelo-frontends/commons';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import useQueryParams from '../../../../hooks/query-params';
import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { IBicycleObservatoryPageContext } from '../../context';

import DownloadDialog from './download-dialog';
import OriginDestinationForm from './form';

function OriginDestinationTab(): JSX.Element {
  const context = useOutletContext<IBicycleObservatoryPageContext & TOutletContext>();
  const {
    period,
    header: { setActions },
    originDestination: { canvasRef, flows },
  } = context;
  const [downloadDialogOpen, openDownloadDialog] = useState(false);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { getPeriods } = useQueryParams();
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    const defaultPeriods = getPeriods(
      moment().get('date') <= 7 ? prevMonth.getPrevPeriod() : prevMonth,
    );

    period.setValues(defaultPeriods);

    return () => {
      setActions(undefined);
    };
  }, []);

  useEffect(() => {
    setActions(
      <Tooltip title={t('commons.actions.export')}>
        <span>
          <IconButton
            color="primary"
            disabled={!flows || flows.length === 0}
            onClick={() => {
              openDownloadDialog(true);
              trackEvent('File Downloaded', {
                pathname: `${window.location.host}${window.location.pathname}`,
                partner_id: currentPartner?.id,
                partner_code: currentPartner?.code,
                file: 'Origin Destination',
              });
            }}
            size="small"
          >
            <FileDownloadOutlined />
          </IconButton>
        </span>
      </Tooltip>,
    );
  }, [flows]);

  return (
    <>
      <PageContentLayout
        leftPanel={<OriginDestinationForm {...context} />}
        main={
          <>
            <canvas
              id="origin-destination-deck-canvas"
              ref={canvasRef}
              style={{
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
              }}
            />
            <Box id="h3-tooltip" position="absolute" sx={{ pointerEvents: 'none' }} />
          </>
        }
      />
      <DownloadDialog onClose={() => openDownloadDialog(false)} open={downloadDialogOpen} />
    </>
  );
}

export default OriginDestinationTab;
