import {
  CyclabilityZone,
  CyclabilityZoneService,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import centroid from '@turf/centroid';
import { cellToBoundary, cellToLatLng, cellsToMultiPolygon, polygonToCells } from 'h3-js';
import { useContext, useEffect, useRef } from 'react';

import { AppContext } from '../../app/context';

const _resolutions = [10, 9, 8, 7, 6] as const;
export type TResolution = (typeof _resolutions)[number];

export const h3EdgeLengthsInMeter: { [key in TResolution]: number } = {
  6: 4000,
  7: 1500,
  8: 500,
  9: 200,
  10: 75,
};

export const defaultResolution: TResolution = 10;

type TH3CellFeatureProps = {
  center: { lat: number; lng: number };
  h3Index: string;
  name?: string;
  resolution: 10 | 9 | 8 | 7 | 6 | 'cyclabilityZones';
  zoneId: string;
};

function useH3({
  zones,
  resolution,
  deckInitialized,
  setH3Features,
  setH3Map,
}: {
  deckInitialized: boolean;
  h3Features?: Array<GeoJSON.Feature<GeoJSON.MultiPolygon | GeoJSON.Polygon, TH3CellFeatureProps>>;
  resolution?: 10 | 9 | 8 | 7 | 6 | 'cyclabilityZones';
  setH3Features: (
    h3Features:
      | Array<GeoJSON.Feature<GeoJSON.MultiPolygon | GeoJSON.Polygon, TH3CellFeatureProps>>
      | undefined,
  ) => void;
  setH3Map?: (map: { [key: string]: { id: number } } | undefined) => void;
  zones?: CyclabilityZone[];
}) {
  const {
    partner: { current: currentPartner, currentArea },
  } = useContext(AppContext);
  const zoneId = useRef(1);
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    if (
      deckInitialized &&
      currentPartner &&
      currentPartner.administrativeLevel &&
      ['region', 'department', 'epci', 'city'].includes(currentPartner.administrativeLevel) &&
      (currentPartner.cyclabilityZoneId || currentArea) &&
      (!resolution || typeof resolution === 'number' || zones)
    ) {
      getH3Features();
    }

    return () => {
      cancelPromises();
      setH3Features(undefined);
      setH3Map?.(undefined);
      zoneId.current = 1;
    };
  }, [deckInitialized, currentPartner, zones, currentArea, resolution]);

  async function getH3Features() {
    if (!currentPartner) return;

    try {
      const partnerCoordinates = currentPartner.cyclabilityZoneId
        ? (
            await cancellablePromise(
              CyclabilityZoneService.getZone(currentPartner.cyclabilityZoneId, {}),
            )
          )?.geometry?.coordinates[0]
        : currentArea?.geometry?.coordinates[0];

      if (partnerCoordinates) {
        const features: Array<
          GeoJSON.Feature<GeoJSON.MultiPolygon | GeoJSON.Polygon, TH3CellFeatureProps>
        > = [];
        const h3Map: { [key in string]: { id: number } } = {};

        if (!resolution || typeof resolution === 'number') {
          const h3Cells = polygonToCells(partnerCoordinates, resolution || defaultResolution, true);

          for (const h3Index of h3Cells) {
            const polygon: GeoJSON.Polygon = {
              type: 'Polygon',
              coordinates: [cellToBoundary(h3Index, true)],
            };
            const [lat, lng] = cellToLatLng(h3Index);

            features.push({
              type: 'Feature',
              geometry: polygon,
              properties: {
                center: { lat, lng },
                resolution: resolution || defaultResolution,
                h3Index,
                zoneId: `${zoneId.current}`,
              },
            });

            h3Map[h3Index] = { id: zoneId.current++ };
          }
        } else if (zones) {
          for (const { id, geometry, name } of zones) {
            if (geometry) {
              const h3Cells = polygonToCells(geometry.coordinates[0], 9);

              const polygon: GeoJSON.MultiPolygon = {
                type: 'MultiPolygon',
                coordinates: cellsToMultiPolygon(h3Cells, false),
              };

              if (polygon.coordinates.length > 0) {
                const [lng, lat] = centroid(polygon).geometry.coordinates;

                features.push({
                  type: 'Feature',
                  geometry: polygon,
                  properties: {
                    center: { lat, lng },
                    resolution: 'cyclabilityZones',
                    h3Index: `cyclability-zone-${id}`,
                    zoneId: `${id}`,
                    name,
                  },
                });
              }
            }
          }
        }

        setH3Features(features);
        setH3Map?.(h3Map);
      }
    } catch (err) {
      console.error(err);
    }
  }

  return { zoneId };
}

export default useH3;
